@import url("https://rsms.me/inter/inter.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        scroll-behavior: smooth;
        font-family: "Inter", sans-serif;
    }
    html,
    body {
        @apply bg-darkBlue text-white h-full w-full text-base;
    }

    .font-exo {
        font-family: "Exo 2", sans-serif;
    }

    /* #__next {
        @apply isolate flex min-h-full flex-col slashed-zero;
    } */
}


@font-face {
    font-family: "BebasNeue-Regular";
    src: url("../../public/fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Onest-Regular";
    src: url("../../public/fonts/Onest-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Onest-Medium";
    src: url("../../public/fonts/Onest-Medium.ttf") format('truetype');
}

#circle_animation {
    text-transform: uppercase;
    animation: spin 14s linear infinite;
    color: #ffffff;
}
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
